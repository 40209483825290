import React from 'react';
import AppContainerView from '../../components/AppContainerView';
import MobileTemplate from '../../components/MobileTemplate';

const IngredientDetail = (props: any) => {
  return (
    <AppContainerView>
      <MobileTemplate></MobileTemplate>
    </AppContainerView>
  );
};

export default IngredientDetail;
